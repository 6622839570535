import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";

const ValueProps = ({ className, headingLevel, heading }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(relativePath: { eq: "repeating/value-props/Why Us 1.svg" }) {
        publicURL
      }
      icon2: file(relativePath: { eq: "repeating/value-props/Why us 2.svg" }) {
        publicURL
      }
      icon3: file(relativePath: { eq: "repeating/value-props/Why Us 3.svg" }) {
        publicURL
      }
    }
  `);

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "Lots Of Options",
      text: "We've built strong partnerships with top lenders, which means you'll get the best options and rates.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "A Tailored Approach",
      text: "We take the time to understand your goals and help you find a loan that’s right for you.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "Easy To Reach",
      text: "At Thistle, we don’t leave you hanging. We’re always here for you when you have questions.",
    },
  ];

  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`${className || "mb-18 md:mb-32"}`}>
      <div className="container">
        <header className="max-w-2xl mx-auto mb-16 md:mb-18 text-center">
          <HeadingTag>{heading || "The Thistle Difference"}</HeadingTag>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-x-10 text-center">
          {content.map((content, i) => {
            return (
              <div key={i}>
                <div className="mb-2">
                  <img
                    src={content.icon}
                    alt={content.heading}
                    className="mx-auto"
                  />
                </div>
                <h3 className="heading-five mb-2">{content.heading}</h3>
                <p className="text-base mb-0">{content.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
