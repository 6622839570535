import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "gatsby-plugin-transition-link";

import Background from "../Background/Background";
import ButtonSolid from "../Button/ButtonSolid";

const ValueProps = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      loanComparisions: file(
        relativePath: { eq: "repeating/resources/Resources 1 - Compare.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 560)
        }
      }
      mortgageBasics: file(
        relativePath: { eq: "repeating/resources/Res 2 - Basics.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 356)
        }
      }
      calculators: file(
        relativePath: { eq: "repeating/resources/Res 3 - Calc.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 356)
        }
      }
      closing: file(
        relativePath: { eq: "repeating/resources/Res 4 - 5 step.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 356)
        }
      }
    }
  `);

  const content = [
    {
      image: data.mortgageBasics.childImageSharp.gatsbyImageData,
      heading: "Mortgage Basics",
      text: "Learn about applying for a loan, how your credit score fits in, appraisals, closing costs, and more.",
      link: "/home-mortgage-basics/",
    },
    {
      image: data.calculators.childImageSharp.gatsbyImageData,
      heading: "Mortgage Calculators",
      text: "How much home can you afford? What would your monthly payment be? Find out with our calculators.",
      link: "/mortgage-calculators/",
    },
    {
      image: data.closing.childImageSharp.gatsbyImageData,
      heading: "5-Step Loan Process",
      text: "The loan process can be daunting, but we’re here to help. We break it down into five simple steps.",
      link: "/loan-process/",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mb-8 md:mb-10 text-center md:text-left">
          <h2 className="mb-0">Your Mortgage Resource Center</h2>
        </header>
        <div className="grid md:grid-cols-2 gap-y-12 md:gap-x-6 lg:gap-x-12 xl:gap-x-18 mb-12 md:mb-0">
          <Background
            backgroundImages={
              data.loanComparisions.childImageSharp.gatsbyImageData
            }
            className="h-full flex items-end"
            padding="pt-64 md:pt-0 pb-12 md:pb-8 px-2 md:px-6 -mx-4 md:mx-0"
          >
            <h3 className="text-white mb-3">Loan Comparisons</h3>
            <p className="text-white mb-4">
              What types of loans are available? Find out what each type of
              program offers.
            </p>
            <ButtonSolid
              href="/home-loan-programs-minnesota/"
              text="Compare Loans"
            />
          </Background>
          <div>
            <div className="grid md:grid-cols-1 gap-y-10 md:gap-y-8 text-center md:text-left">
              {content.map((content, i) => {
                return (
                  <Link
                    to={content.link}
                    className="group grid md:grid-cols-11 md:gap-x-4 lg:gap-x-8 gap-y-4"
                    key={i}
                  >
                    <div className="overflow-hidden md:col-start-1 md:col-span-4">
                      <GatsbyImage
                        image={content.image}
                        alt={content.heading}
                        className="transform w-full scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                      />
                    </div>
                    <div className="md:col-end-13 md:col-span-8">
                      <h3 className="heading-four mb-2">{content.heading}</h3>
                      <p className="mb-0">{content.text}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
