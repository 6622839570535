import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import ButtonVerticalLines from "../Button/ButtonVerticalLines";

const About = ({ className, headingLevel }) => {
  const data = useStaticQuery(graphql`
    {
      team: file(relativePath: { eq: "repeating/about/mark-thistle.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 498)
        }
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  return (
    <section
      className={`bg-gray-50 py-20 md:py-32 ${className || "mb-16 md:mb-32"}`}
    >
      <div className="container px-12 md:px-4">
        <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-18 gap-y-8 items-center">
          <div className="md:col-start-1 md:col-span-5">
            <GatsbyImage
              image={data.team.childImageSharp.gatsbyImageData}
              width="560"
              className="shadow-3xl"
            />
          </div>
          <div className="md:col-end-13 md:col-span-7">
            <HeadingTag>Hi, I'm Mark Thistle</HeadingTag>
            <p>
              I'm an independent mortgage broker based in Eden Prairie with more
              than 20 years of financial industry experience, primarily in
              mortgage financing. You come first in my bookㅡyou can count on me
              and my team to be there for every step of your home buying
              journey.
            </p>
            <div className="flex justify-center md:justify-start">
              <ButtonVerticalLines href="/about-us/" text="My Story" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
