import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "What if I don’t know how to manage a property?",
      answer: (
        <>
          <p>
            At Thistle, we’ve helped many people become landlords and investment
            owner operators. We can coach you on how to manage your investment
            property.
          </p>
        </>
      ),
    },
    {
      question:
        "Can I purchase a rental property with little or no money down?",
      answer: (
        <>
          <p>
            There are ways to buy a rental property if you’re short on cash for
            a down payment:
          </p>

          <ul className="pl-6 list-disc mb-6">
            <li>
              If you own a home and have enough equity in it, you can cash that
              out to use as a down payment on a rental property.
            </li>
            <li>
              If you already own a home, you can move into a new home and then
              use the one you're in now as a rental.
            </li>
            <li>
              You can partner with a co-borrower interested in joining you in
              the investment and who has money for the down payment.
            </li>
            <li>
              You can assume a mortgage, where you simply take over the mortgage
              payments in exchange for the title to the property.
            </li>
            <li>
              You can seek out properties that offer owner or seller financing.
              The seller finances the sale themselves and may provide terms with
              little or no money down.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "What tax deductions can I take if I own a rental property?",
      answer: (
        <>
          <p>
            You'll want to consult with a tax advisor for specifics, but
            typically you can deduct mortgage interest, property tax, repairs,
            and depreciation. Of course, your regular operating expenses and
            property improvements can be deducted as well.
          </p>
        </>
      ),
    },
    {
      question:
        "What should I research before buying a long-term rental property?",
      answer: (
        <>
          <p>
            When considering purchasing a rental property, it's important to do
            your homework. After all, your goal is to be profitable. You should
            investigate:
          </p>
          <ul className="pl-6 list-disc mb-6">
            <li>
              <b>The Neighborhood:</b> What’s the community like? What type of
              tenants might be interested in living there?
            </li>
            <li>
              <b>Property Taxes:</b> Are they unnecessarily high considering the
              location?
            </li>
            <li>
              <b>Schools:</b> If you're renting out family homes, you'll want to
              check the quality of the area's schools.
            </li>
            <li>
              <b>Amenities:</b> What will attract renters to the area? It might
              be parks, public transportation, great shopping or restaurants.
            </li>
            <li>
              <b>Job Market:</b> What types of employers are in the area? Will
              workers be attracted to living there?
            </li>
            <li>
              <b>The Number of Listings & Vacancies:</b> Are there high vacancy
              rates in the neighborhood? If so, what's the reason?
            </li>
            <li>Crime: Is the neighborhood a hot spot for crime? </li>
            <li>
              <b>Future Development:</b> What's zoned for the area? Are there
              any plans to add more housing, which could compete with your
              property?
            </li>
            <li>
              <b>Average Rents:</b> Will you be able to collect enough rent to
              cover all of your rental expenses?
            </li>
            <li>
              <b>Natural Disasters:</b> Is the area prone to flooding or
              earthquakes? If so, you may need to pay for additional insurance.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Mortgage Broker for Investors | Thistle Financial"
        description="Get advice from an experienced mortgage broker for investors. We'll help you find the right type of property that fits your plan for the future."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        padding="pb-16 md:pb-0"
      >
        <h1>Mortgage Broker For Investors</h1>
        <p>
          Whether you’re considering investing in a rental property for the
          first time or want to add to your existing portfolio, Thistle
          Financial is the place to start. As an expert mortgage broker for
          investors, we can advise you on everything from how to be a landlord
          to finding the right type of property that fits your plan for the
          future.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="bg-primary-50 py-20 md:py-32 mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-24 text-center">
            <h2>Why It’s Good to Invest in Rental Property</h2>
          </header>

          <div className="grid md:grid-cols-3 gay-y-8 md:gap-x-5 lg:gap-x-10 text-center">
            <div>
              <img
                src={data.icon1.publicURL}
                alt="Increase Your Cash Flow"
                className="mx-auto mb-6"
              />
              <h3>Increase Your Cash Flow</h3>
              <p className="mb-0">
                Get cash flow from monthly rental income and build yourself a
                passive income source.
              </p>
            </div>

            <div>
              <img
                src={data.icon2.publicURL}
                alt="Get Tax Benefits"
                className="mx-auto mb-6"
              />
              <h3>Get Tax Benefits</h3>
              <p className="mb-0">
                Property investors are entitled to tax benefits, such as a wide
                range of deductions.
              </p>
            </div>

            <div>
              <img
                src={data.icon3.publicURL}
                alt="Build Long-Term Asset Appreciation"
                className="mx-auto mb-6"
              />
              <h3>Build Long-Term Asset Appreciation</h3>
              <p className="mb-0">
                Real estate is an investment that builds equity and can be a
                hedge against inflation.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-24 text-center">
            <h2>Types of Investment Properties</h2>
          </header>

          <div className="grid md:grid-cols-2 gay-y-8 md:gap-x-10 lg:gap-x-20 items-center mb-14 md:mb-18">
            <div>
              <GatsbyImage
                image={data.singleFamilyHomes.childImageSharp.gatsbyImageData}
              />
            </div>
            <div>
              <h3>Single-Family Home</h3>
              <p className="mb-0">
                There's a steady demand for traditional rental homes, making
                them a strong investment and an excellent source of passive
                income. Purchase options include single-family homes, townhomes,
                and condos. At Thistle, we've helped many people become property
                investment owners and have solutions and strategies that'll help
                with the down payment.
              </p>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gay-y-8 md:gap-x-10 lg:gap-x-20 items-center">
            <div className="order-2 md:order-1">
              <h3>Multi-Family Property</h3>
              <p className="mb-0">
                If you’re interested in a step up from a traditional
                single-family rental, you can purchase a multi-family property,
                such as a duplex, triplex, or 4-plex. Typically, financing is
                the same as it would be for a single-family home and if you
                decide to live in one of the units, you may qualify for a lower
                down payment and interest rate.
              </p>
            </div>
            <div className="order-1 md:order-2">
              <GatsbyImage
                image={data.multiFamilyProperty.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>Frequently Asked Questions</h2>
          </header>

          <Accordion
            allowZeroExpanded={true}
            className="border-t border-solid border-primary-600/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    className="border-b border-solid border-primary-600/20 pt-6 md:pt-7 pb-5 md:pb-6"
                    uuid={i}
                  >
                    <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                      <p className="font-heading text-xl md:text-3xl font-bold mb-0">
                        {faq.question}
                      </p>
                      <AccordionItemState>
                        {(state) => {
                          const icon = state.expanded
                            ? "fa-chevron-up"
                            : "fa-chevron-down";
                          return (
                            <i
                              className={`far ${icon} text-lg text-primary-400 ml-4`}
                            ></i>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 max-w-4xl animate-fadeIn">
                      <p className="mb-0">{faq.answer}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <ValueProps />
      <About className="mb-0" />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Investor.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Investor.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "2.4 Investor/1.0 Hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
    icon1: file(relativePath: { eq: "2.4 Investor/Icon 1.svg" }) {
      publicURL
    }
    icon2: file(relativePath: { eq: "2.4 Investor/Icon 2.svg" }) {
      publicURL
    }
    icon3: file(relativePath: { eq: "2.4 Investor/Icon 3.svg" }) {
      publicURL
    }
    multiFamilyProperty: file(
      relativePath: { eq: "2.4 Investor/Multi-Family Property.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
    singleFamilyHomes: file(
      relativePath: { eq: "2.4 Investor/Single-Family Homes.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
  }
`;
export default Page;
